import {
	HomeOutlined,
	PercentageOutlined,
	ProfileOutlined,
	ExperimentOutlined,
	CalculatorOutlined,
	DollarOutlined,
	ApiOutlined,
	FileExclamationOutlined,
	ShoppingOutlined,
	DeploymentUnitOutlined,
	TagOutlined,
	UserOutlined,
	UsergroupAddOutlined,
	EnvironmentOutlined,
	DotChartOutlined,
	AuditOutlined,
	FormOutlined,
	AlertOutlined,
	RiseOutlined,
	FileImageOutlined,
	FileDoneOutlined,
	SwapOutlined,
	CopyOutlined,
	UnorderedListOutlined,
	ImportOutlined,
	ExportOutlined,
	ShopOutlined,
	NotificationOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import Permissoes from "enum/permissao-enum";
import {
	ProdutorSVG,
	FazendaSVG,
	DoencaSVG,
	DaninhaSVG,
	RelatorioPDFSVG,
	ExportacaoSVG,
} from "../assets/svg/icon";
import { Icon } from "components/util-components/SvgIcon";

const dashBoardNavTree = [
	{
		key: "lista-cards-aprovacao",
		path: `${APP_PREFIX_PATH}/lista-cards-aprovacao`,
		title: "lista-cards-aprovacao",
		icon: FileImageOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [Permissoes.CARDS_APROVACAO.VISUALIZAR_PROPRIA_REGIONAL],
	},
	
	{
		key: "lista-campanhas",
		path: `${APP_PREFIX_PATH}/lista-campanhas`,
		title: "lista-campanhas",
		icon: TagOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.CAMPANHAS.VISUALIZAR,
	},
	{
		key: "lista-produtores",
		path: `${APP_PREFIX_PATH}/lista-produtores`,
		title: "lista-produtores",
		icon: ProdutorSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.PRODUTORES.VISUALIZAR,
	},
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "exportacao",
		path: `${APP_PREFIX_PATH}/exportacao`,
		title: "exportacao",
		icon: ExportacaoSVG,
		breadcrumb: false,
		submenu: [],
		permissao: [
			Permissoes.EXPORTACAO.EXPORTAR_PROPRIOS,
			Permissoes.EXPORTACAO.EXPORTAR_REGIONAL,
			Permissoes.EXPORTACAO.EXPORTAR_TODOS,
		],
	},
	{
		key: "lista-fazendas",
		path: `${APP_PREFIX_PATH}/lista-fazendas`,
		title: "lista-fazendas",
		icon: FazendaSVG,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.FAZENDAS.VISUALIZAR,
	},
	{
		key: "lista-geracao-card",
		path: `${APP_PREFIX_PATH}/lista-geracao-card`,
		title: "lista-geracao-card",
		icon: FileImageOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [Permissoes.CARDS_APROVACAO.GERAR_CARDS],
	},
	{
		breadcrumb: false,
		icon: () => <Icon name="comments-dollar" />,
		key: "outflows",
		path: `${APP_PREFIX_PATH}/giros-venda`,
		permissao: [
			Permissoes.REPOSICAO_ESTOQUE.VISUALIZAR_PROPRIA_REGIONAL,
			Permissoes.REPOSICAO_ESTOQUE.VISUALIZAR_TODOS,
		],
		submenu: [],
		title: "outflows-menu-entry",
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.GRUPOS_DE_USUARIO.VISUALIZAR,
	},
	{
		key: "lista-leads",
		path: `${APP_PREFIX_PATH}/lista-leads`,
		title: "lista-leads",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.LEADS.VISUALIZAR,
	},
	{
		key: "migracao-clientes",
		path: `${APP_PREFIX_PATH}/migracao-clientes`,
		title: "migracao-clientes",
		icon: SwapOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [Permissoes.MIGRACAO_CLIENTES.MIGRAR],
	},
	{
		key: "notificacao",
		path: `${APP_PREFIX_PATH}/notificacao`,
		title: "notificacao",
		icon: NotificationOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [Permissoes.NOTIFICACOES.ENVIAR_NOTIFICACAO],
	},
	{
		key: "lista-ocorrencias",
		path: `${APP_PREFIX_PATH}/lista-ocorrencias`,
		title: "lista-ocorrencias",
		icon: AlertOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			Permissoes.OCORRENCIAS.VISUALIZAR_PROPRIAS,
			Permissoes.OCORRENCIAS.VISUALIZAR_PARTICIPA_DO_FLUXO,
			Permissoes.OCORRENCIAS.VISUALIZAR_TODAS,
			Permissoes.OCORRENCIAS.VISUALIZAR_REGIONAIS,
		],
	},
	{
		key: "pdf-de-manual",
		path: `${APP_PREFIX_PATH}/pdf-de-manual`,
		title: "pdf-de-manual",
		icon: CopyOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [Permissoes.PDF_MANUAL.VISUALIZAR],
	},
	{
		key: "relatorio",
		path: `${APP_PREFIX_PATH}/relatorio`,
		title: "relatorio",
		icon: RelatorioPDFSVG,
		breadcrumb: false,
		submenu: [],
		permissao: [
			Permissoes.GERAR_PDF_VISITA.GERAR_PDF_PROPRIOS,
			Permissoes.GERAR_PDF_VISITA.GERAR_PDF_REGIONAL,
			Permissoes.GERAR_PDF_VISITA.GERAR_PDF_TODOS,
		],
	},
	{
		key: "lista-pedidos",
		path: `${APP_PREFIX_PATH}/lista-pedidos`,
		title: "lista-pedidos",
		icon: AuditOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			Permissoes.PEDIDOS.VISUALIZAR_TODOS,
			Permissoes.PEDIDOS.VISUALIZAR_PROPRIA_REGIONAL,
			Permissoes.PEDIDOS.VISUALIZAR_PROPRIOS,
		],
	},
	{
		key: "lista-hibridos",
		path: `${APP_PREFIX_PATH}/lista-hibridos`,
		title: "lista-hibridos",
		icon: DeploymentUnitOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.HIBRIDOS.VISUALIZAR,
	},
	{
		key: "lista-resultados-comerciais",
		path: `${APP_PREFIX_PATH}/lista-resultados-comerciais`,
		title: "lista-resultados-comerciais",
		icon: FileDoneOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-tabela-precos",
		path: `${APP_PREFIX_PATH}/lista-tabela-precos`,
		title: "lista-tabela-precos",
		icon: ShoppingOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.TABELA_DE_PRECOS.VISUALIZAR,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: Permissoes.USUARIOS.VISUALIZAR,
	},
	{
		key: "lista-visitas",
		path: `${APP_PREFIX_PATH}/lista-visitas`,
		title: "lista-visitas",
		icon: FazendaSVG,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "cadastros-secundario",
		title: "cadastros-secundario",
		submenu: [
			{
				key: "cadastro-secundario",
				title: "cadastro-secundario",
				icon: FormOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: "lista-agroquimicos",
						path: `${APP_PREFIX_PATH}/lista-agroquimicos`,
						title: "lista-agroquimicos",
						icon: DotChartOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.AGROQUIMICOS.VISUALIZAR,
					},
					{
						key: "list-comissoes-por-desconto",
						path: `${APP_PREFIX_PATH}/list-comissoes-por-desconto`,
						title: "list-comissoes-por-desconto",
						icon: PercentageOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.COMISSOES_POR_DESCONTO.VISUALIZAR,
					},
					{
						key: "lista-cultura",
						path: `${APP_PREFIX_PATH}/lista-cultura`,
						title: "lista-cultura",
						icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.CULTURA.VISUALIZAR,
					},
					{
						key: "lista-daninhas",
						path: `${APP_PREFIX_PATH}/lista-daninhas`,
						title: "lista-daninhas",
						icon: DaninhaSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.DANINHAS.VISUALIZAR,
					},
					{
						key: "lista-doencas",
						path: `${APP_PREFIX_PATH}/lista-doencas`,
						title: "lista-doencas",
						icon: DoencaSVG,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.DOENÇAS.VISUALIZAR,
					},
					{
						key: "lista-empresa",
						path: `${APP_PREFIX_PATH}/lista-empresa`,
						title: "lista-empresa",
						icon: ShopOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.EMPRESA.VISUALIZAR,
					},
					{
						key: "lista-estimulador-venda",
						path: `${APP_PREFIX_PATH}/lista-estimulador-venda`,
						title: "lista-estimulador-venda",
						icon: RiseOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.ESTIMULADOR_VENDA.VISUALIZAR,
					},
					{
						key: "lista-formas-pagamento",
						path: `${APP_PREFIX_PATH}/lista-formas-pagamento`,
						title: "lista-formas-pagamento",
						icon: DollarOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.FORMAS_DE_PAGAMENTO.VISUALIZAR,
					},
					{
						key: "lista-interesse",
						path: `${APP_PREFIX_PATH}/lista-interesse`,
						title: "lista-interesse",
						icon: DollarOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.INTERESSE.VISUALIZAR,
					},
					{
						key: "lista-motivo-visita-comercial",
						path: `${APP_PREFIX_PATH}/lista-motivo-visita-comercial`,
						title: "lista-motivo-visita-comercial",
						icon: UnorderedListOutlined,
						breadcrumb: false,
						submenu: [],
						permissao:
							Permissoes.MOTIVO_VISITA_COMERCIAL.VISUALIZAR,
					},
					{
						key: "lista-motivo-perda",
						path: `${APP_PREFIX_PATH}/lista-motivo-perda`,
						title: "lista-motivo-perda",
						icon: ExportOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.MOTIVO_PERDA.VISUALIZAR,
					},
					{
						key: "lista-origem",
						path: `${APP_PREFIX_PATH}/lista-origem`,
						title: "lista-origem",
						icon: ImportOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.MOTIVO_PERDA.VISUALIZAR,
					},
					{
						key: "lista-regionais",
						path: `${APP_PREFIX_PATH}/lista-regionais`,
						title: "lista-regionais",
						icon: EnvironmentOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.REGIONAIS.VISUALIZAR,
					},
					{
						key: "lista-tratamentos",
						path: `${APP_PREFIX_PATH}/lista-tratamentos`,
						title: "lista-tratamentos",
						icon: ExperimentOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.TRATAMENTOS.VISUALIZAR,
					},
					{
						key: "lista-unidades-medida",
						path: `${APP_PREFIX_PATH}/lista-unidades-medida`,
						title: "lista-unidades-medida",
						icon: CalculatorOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.UNIDADES_DE_MEDIDA.VISUALIZAR,
					},
				],
			},
			{
				breadcrumb: false,
				icon: () => <Icon name="boxes-stacked" />,
				key: "stock-and-outflows",
				permissao: Permissoes.REPOSICAO_ESTOQUE.VISUALIZAR_TODOS,
				submenu: [
					{
						breadcrumb: false,
						icon: () => <Icon name="dolly" />,
						key: "restock-items",
						path: `${APP_PREFIX_PATH}/itens-reposicao-estoque`,
						permissao:
							Permissoes.REPOSICAO_ESTOQUE.VISUALIZAR_TODOS,
						submenu: [],
						title: "restock-items-menu-entry",
					},
					{
						breadcrumb: false,
						icon: () => <Icon name="person-dolly" />,
						key: "restocks",
						path: `${APP_PREFIX_PATH}/reposicoes-estoque`,
						permissao:
							Permissoes.REPOSICAO_ESTOQUE.VISUALIZAR_TODOS,
						submenu: [],
						title: "restocks-menu-entry",
					},
				],
				title: "stock-and-outflows-menu-entry",
			},
		],
	},
	{
		key: "integracao",
		title: "integracao",
		permissao: [
			Permissoes.INTEGRACAO_SAP.VISUALIZAR,
			Permissoes.REGRAS_SAP.VISUALIZAR,
		],
		submenu: [
			{
				key: "integracao-sap",
				title: "integracao-sap",
				icon: ApiOutlined,
				breadcrumb: false,
				indexNotificacoes: "somaErrosSap",
				submenu: [
					{
						key: "integracao-sap/lista-pedidos-erro-lead",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro-lead`,
						title: "integracao-sap/lista-pedidos-erro-lead",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "pedidosComErroLead",
					},
					{
						key: "integracao-sap/lista-produtores-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-produtores-erro`,
						title: "integracao-sap/lista-produtores-erro",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "produtoresComErro",
					},
					{
						key: "integracao-sap/lista-usuarios-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-usuarios-erro`,
						title: "integracao-sap/lista-usuarios-erro",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "consultoresComErro",
					},
					{
						key: "integracao-sap/lista-enderecos-validar",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-enderecos-validar`,
						title: "integracao-sap/lista-enderecos-validar",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "enderecosAValidar",
					},
					{
						key: "integracao-sap/lista-hibridos-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-hibridos-erro`,
						title: "integracao-sap/lista-hibridos-erro",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "hibridosComErro",
					},
					{
						key: "integracao-sap/lista-pedidos-erro",
						path: `${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro`,
						title: "integracao-sap/lista-pedidos-erro",
						icon: FileExclamationOutlined,
						breadcrumb: false,
						submenu: [],
						indexNotificacoes: "pedidosComErro",
					},
					// {
					//   key: 'integracao-sap/lista-comissoes-erro',
					//   path: `${APP_PREFIX_PATH}/integracao-sap/lista-comissoes-erro`,
					//   title: 'integracao-sap/lista-comissoes-erro',
					//   icon: FileExclamationOutlined,
					//   breadcrumb: false,
					//   submenu: [],
					//   indexNotificacoes: 'comissoesComErro',
					// },
				],
			},
			{
				key: "regras-integracao-sap",
				title: "regras-integracao-sap",
				icon: ApiOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: "regras-sap-centro-custo",
						path: `${APP_PREFIX_PATH}/regras-sap-centro-custo`,
						title: "regras-sap-centro-custo",
						icon: ApiOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.REGRAS_SAP.VISUALIZAR,
					},
					{
						key: "regras-sap-filial",
						path: `${APP_PREFIX_PATH}/regras-sap-filial`,
						title: "regras-sap-filial",
						icon: ApiOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.REGRAS_SAP.VISUALIZAR,
					},
					{
						key: "regras-sap-operacao-interna",
						path: `${APP_PREFIX_PATH}/regras-sap-operacao-interna`,
						title: "regras-sap-operacao-interna",
						icon: ApiOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.REGRAS_SAP.VISUALIZAR,
					},
					{
						key: "regras-sap-utilizacao",
						path: `${APP_PREFIX_PATH}/regras-sap-utilizacao`,
						title: "regras-sap-utilizacao",
						icon: ApiOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: Permissoes.REGRAS_SAP.VISUALIZAR,
					},
				],
			},
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
