const Permissoes = {};

Permissoes.USUARIOS = {
	VISUALIZAR: 1,
	CRIAR: 2,
	EXCLUIR: 3,
};

Permissoes.PRODUTORES = {
	VISUALIZAR: 4,
	CRIAR: 5,
	EXCLUIR: 6,
};

Permissoes.FAZENDAS = {
	VISUALIZAR: 7,
	CRIAR: 8,
	EXCLUIR: 9,
};

Permissoes.PEDIDOS = {
	VISUALIZAR_TODOS: 10,
	VISUALIZAR_PROPRIA_REGIONAL: 51,
	VISUALIZAR_PROPRIOS: 52,
	CRIAR: 11,
	EXCLUIR: 12,
	MANTER_A_PRAZO: 110,
	APROVAR_PENDENTE_SERASA: 111,
};

Permissoes.TABELA_DE_PRECOS = {
	VISUALIZAR: 13,
	CRIAR: 14,
	EXCLUIR: 15,
};

Permissoes.CAMPANHAS = {
	VISUALIZAR: 16,
	CRIAR: 17,
	EXCLUIR: 18,
};

Permissoes.HIBRIDOS = {
	VISUALIZAR: 19,
	CRIAR: 20,
	EXCLUIR: 21,
};

Permissoes.REGIONAIS = {
	VISUALIZAR: 22,
	CRIAR: 23,
	EXCLUIR: 24,
};

Permissoes.COMISSOES_POR_DESCONTO = {
	VISUALIZAR: 25,
	CRIAR: 26,
	EXCLUIR: 27,
};

Permissoes.TRATAMENTOS = {
	VISUALIZAR: 28,
	CRIAR: 29,
	EXCLUIR: 30,
};

Permissoes.UNIDADES_DE_MEDIDA = {
	VISUALIZAR: 31,
	CRIAR: 32,
	EXCLUIR: 33,
};

Permissoes.FORMAS_DE_PAGAMENTO = {
	VISUALIZAR: 34,
	CRIAR: 35,
	EXCLUIR: 36,
};

Permissoes.DOENÇAS = {
	VISUALIZAR: 37,
	CRIAR: 38,
	EXCLUIR: 39,
};

Permissoes.DANINHAS = {
	VISUALIZAR: 40,
	CRIAR: 41,
	EXCLUIR: 42,
};

Permissoes.AGROQUIMICOS = {
	VISUALIZAR: 43,
	CRIAR: 44,
	EXCLUIR: 45,
};

Permissoes.INTEGRACAO_SAP = {
	VISUALIZAR: 46,
	INTEGRAR: 47,
};

Permissoes.GRUPOS_DE_USUARIO = {
	VISUALIZAR: 48,
	CRIAR: 49,
	EXCLUIR: 50,
};

Permissoes.OCORRENCIAS = {
	VISUALIZAR_PROPRIAS: 53,
	VISUALIZAR_PARTICIPA_DO_FLUXO: 54,
	VISUALIZAR_TODAS: 55,
	REALIZAR_PESQUISA: 56,
	VISUALIZAR_PESQUISA: 60,
	VISUALIZAR_REGIONAIS: 73,
};

Permissoes.REPOSICAO_ESTOQUE = {
	VISUALIZAR_PROPRIOS: 57,
	VISUALIZAR_PROPRIA_REGIONAL: 58,
	VISUALIZAR_TODOS: 59,
};

Permissoes.ESTIMULADOR_VENDA = {
	VISUALIZAR: 62,
	CRIAR: 63,
	EXCLUIR: 64,
};

Permissoes.CARDS_APROVACAO = {
	VISUALIZAR_PROPRIA_REGIONAL: 65,
	APROVAR_REPROVAR_PROPRIA_REGIONAL: 66,
	VISUALIZAR_TODOS: 70,
	GERAR_CARDS: 71,
};

Permissoes.REGRAS_SAP = {
	VISUALIZAR: 67,
	CRIAR: 68,
	EXCLUIR: 69,
};

Permissoes.MIGRACAO_CLIENTES = {
	MIGRAR: 72,
};

Permissoes.LEADS = {
	VISUALIZAR: 74,
	IMPORTAR: 75,
	CRIAR: 76,
	EDITAR: 77,
	EXCLUIR: 78,
};

Permissoes.PDF_MANUAL = {
	VISUALIZAR: 79,
};

Permissoes.MOTIVO_PERDA = {
	VISUALIZAR: 80,
	CRIAR: 81,
	EDITAR: 82,
	EXCLUIR: 83,
};

Permissoes.ORIGEM = {
	VISUALIZAR: 84,
	CRIAR: 85,
	EDITAR: 86,
	EXCLUIR: 87,
};

Permissoes.MOTIVO_VISITA_COMERCIAL = {
	VISUALIZAR: 88,
	CRIAR: 89,
	EDITAR: 90,
	EXCLUIR: 91,
};

Permissoes.EMPRESA = {
	VISUALIZAR: 92,
	CRIAR: 93,
	EDITAR: 94,
	EXCLUIR: 95,
};

Permissoes.INTERESSE = {
	VISUALIZAR: 96,
	CRIAR: 97,
	EDITAR: 98,
	EXCLUIR: 99,
};

Permissoes.CULTURA = {
	VISUALIZAR: 100,
	CRIAR: 101,
	EDITAR: 102,
	EXCLUIR: 103,
};

Permissoes.EXPORTACAO = {
	EXPORTAR_PROPRIOS: 104,
	EXPORTAR_REGIONAL: 105,
	EXPORTAR_TODOS: 106,
};

Permissoes.GERAR_PDF_VISITA = {
	GERAR_PDF_PROPRIOS: 107,
	GERAR_PDF_REGIONAL: 108,
	GERAR_PDF_TODOS: 109,
};

Permissoes.NOTIFICACOES = {
	ENVIAR_NOTIFICACAO: 112,
};

export default Permissoes;
